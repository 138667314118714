import React, { useState, useEffect } from "react";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { HOST } from "utils/config";
import { get, post } from "utils/helper";
import Footer from "layouts/Footer";
import DataTable from "components/Tables/DataTable";
import moment from "moment";
import { Grid } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiDatePicker from "components/SuiDatePicker";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { FormControlLabel, Switch } from "@mui/material";
import { Formik, Form, FieldArray, FastField } from "formik";
import colors from "assets/theme/base/colors";
import Skeleton from "@mui/material/Skeleton";
import manageStyle from "./styles";
import { mainTable, mainTableUser } from "./mainheadtable";
import { columnsDetail, columnsDetailUser } from "./theadtable";
import {
  TextBlack30,
  TextCenter,
  LootoList20,
  LootoList18,
  LootoList16,
  LootoList16span,
  LootoList20_2,
  LootoList20_3,
  LootoList36,
  TabsMargin,
} from "components/Text/TextStyle";
const { info, white, primary, success } = colors;

export default function WinLoseReport() {
  const classes = manageStyle();

  const [lottoList, setLottoList] = useState([]);
  const [startdate, setStartdate] = useState(moment().format("DD/MM/YYYY"));
  const [endtdate, setEnddate] = useState(moment().format("DD/MM/YYYY"));
  const [buyListByUserItem, setBuyListByUserItem] = useState({});
  const [buyListByLottoItem, setBuyListByLottoItem] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchGetDetailBuy = async () => {
    setIsLoading(true);
    try {
      const { data } = await get(`${HOST}api/UserConfig/GetMarket`);
      data?.results.map((e, i) => {
        e.gatcha_list.map((el, index) => (el.check = true));
      });

      setLottoList(data.results);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const fetchUser = async () => {
    setIsLoading(true);
    try {
      let lottolistArr = [];
      lottoList.map((e, i) =>
        e.gatcha_list.map((el, index) => {
          if (el.check) {
            lottolistArr.push({ gatcha_id: el.gatcha_id });
          }
        })
      );
      const payload = lottolistArr;
      const { data } = await post(
        `${HOST}api/Report/GetReportByUserList?start_date=${startdate}&end_date=${endtdate}`,
        payload
      );

      const dataDetail = {
        columns: columnsDetail,
        rows: rednderTotal(data.result.buyListByLottoItem) || [],
      };
      const dataDetailUser = {
        columns: columnsDetailUser,

        rows: rednderTotalUser(data.result.buyListByUserItem),
      };
      setBuyListByUserItem(dataDetailUser);
      setBuyListByLottoItem(dataDetail);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const rednderTotal = (data) => {
    let total_buy_all = 0;
    let downline_receive_all = 0;
    let downline_discount_all = 0;
    let downline_receive_discount_all = 0;
    let agent_total_receive_all = 0;
    let agent_total_discount_all = 0;
    let agent_total_receive_discount_all = 0;
    let upline_receive_all = 0;
    let upline_discount_all = 0;
    let upline_receive_total_all = 0;
    let downline_total_reward_all = 0;
    let downline_summary_all = 0;
    let agent_total_reward_all = 0;
    let agent_summary_all = 0;
    let upline_total_reward_all = 0;
    let upline_summary_all = 0;

    const datatotal =
      data &&
      data.map((e, i) => {
        {
          total_buy_all = total_buy_all + e.total_buy;
          downline_receive_all = downline_receive_all + e.downline_receive;
          downline_discount_all = downline_discount_all + e.downline_discount;
          downline_receive_discount_all =
            downline_receive_discount_all + e.downline_receive_discount;
          agent_total_receive_all = agent_total_receive_all + e.agent_total_receive;
          agent_total_discount_all = agent_total_discount_all + e.agent_total_discount;
          agent_total_receive_discount_all =
            agent_total_receive_discount_all + e.agent_total_receive_discount;
          upline_receive_all = upline_receive_all + e.upline_receive;
          upline_discount_all = upline_discount_all + e.upline_discount;
          upline_receive_total_all = upline_receive_total_all + e.upline_receive_total;
          downline_total_reward_all = downline_total_reward_all + e.downline_total_reward;
          downline_summary_all = downline_summary_all + e.downline_summary;
          agent_total_reward_all = agent_total_reward_all + e.agent_total_reward;
          agent_summary_all = agent_summary_all + e.agent_summary;
          upline_total_reward_all = upline_total_reward_all + e.upline_total_reward;
          upline_summary_all = upline_summary_all + e.upline_summary;
        }
      });
    let datatofix = data.map((e, i) => ({
      gatcha_name: e.gatcha_name,
      downline_user_id: e.downline_user_id,
      downline_name: e.downline_name,
      total_buy: e.total_buy?.toLocaleString(undefined, { maximumFractionDigits: 2 }),
      downline_receive: e.downline_receive?.toLocaleString(undefined, { maximumFractionDigits: 2 }),
      downline_discount: e.downline_discount?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
      downline_receive_discount: e.downline_receive_discount?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
      downline_total_reward: e.downline_total_reward?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
      agent_total_receive: e.agent_total_receive?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
      agent_total_discount: e.agent_total_discount?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
      agent_total_receive_discount: e.agent_total_receive_discount?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
      agent_total_reward: e.agent_total_reward?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
      upline_receive: e.upline_receive?.toLocaleString(undefined, { maximumFractionDigits: 2 }),
      upline_discount: e.upline_discount?.toLocaleString(undefined, { maximumFractionDigits: 2 }),
      upline_receive_total: e.upline_receive_total?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
      upline_total_reward: e.upline_total_reward?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
      downline_summary: e.downline_summary?.toLocaleString(undefined, { maximumFractionDigits: 2 }),
      agent_summary: e.agent_summary?.toLocaleString(undefined, { maximumFractionDigits: 2 }),
      upline_summary: e.upline_summary?.toLocaleString(undefined, { maximumFractionDigits: 2 }),
    }));
    return [
      ...datatofix,
      {
        gatcha_name: (
          <SuiBox textAlign="end" width={"100%"}>
            รวม
          </SuiBox>
        ),
        total_buy: total_buy_all.toLocaleString(undefined, { maximumFractionDigits: 2 }),
        downline_receive: downline_receive_all.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }),
        downline_discount: downline_discount_all.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }),
        downline_receive_discount: downline_receive_discount_all.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }),
        agent_total_receive: agent_total_receive_all.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }),
        agent_total_discount: agent_total_discount_all.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }),
        agent_total_receive_discount: agent_total_receive_discount_all.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }),
        upline_receive: upline_receive_all.toLocaleString(undefined, { maximumFractionDigits: 2 }),
        upline_discount: upline_discount_all.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }),
        upline_receive_total: upline_receive_total_all.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }),
        downline_total_reward: downline_total_reward_all.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }),
        downline_summary: downline_summary_all.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }),
        agent_total_reward: agent_total_reward_all.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }),
        agent_summary: agent_summary_all.toLocaleString(undefined, { maximumFractionDigits: 2 }),
        upline_total_reward: upline_total_reward_all.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }),
        upline_summary: upline_summary_all.toLocaleString(undefined, { maximumFractionDigits: 2 }),
      },
    ];
  };

  const rednderTotalUser = (data) => {
    let total_buy = 0;
    let downline_receive_discount = 0;
    let downline_discount = 0;
    let downline_receive = 0;
    let agent_total_receive_discount = 0;
    let agent_total_receive = 0;
    let agent_total_discount = 0;
    let upline_receive = 0;
    let upline_discount = 0;
    let upline_receive_total = 0;
    let downline_total_reward_all = 0;
    let downline_summary_all = 0;
    let agent_total_reward_all = 0;
    let agent_summary_all = 0;
    let upline_total_reward_all = 0;
    let upline_summary_all = 0;
    const datatotal =
      data &&
      data.map((elem, i) => {
        // downline_user_id = downline_user_id + elem.downline_user_id;
        // downline_name = downline_name + elem.downline_name;
        total_buy = total_buy + elem.total_buy;
        downline_receive = downline_receive + elem.downline_receive;
        downline_discount = downline_discount + elem.downline_discount;
        downline_receive_discount = downline_receive_discount + elem.downline_receive_discount;
        agent_total_receive = agent_total_receive + elem.agent_total_receive;
        agent_total_discount = agent_total_discount + elem.agent_total_discount;
        agent_total_receive_discount =
          agent_total_receive_discount + elem.agent_total_receive_discount;
        upline_receive = upline_receive + elem.upline_receive;
        upline_discount = upline_discount + elem.upline_discount;
        upline_receive_total = upline_receive_total + elem.upline_receive_total;
        downline_total_reward_all = downline_total_reward_all + elem.downline_total_reward;
        downline_summary_all = downline_summary_all + elem.downline_summary;
        agent_total_reward_all = agent_total_reward_all + elem.agent_total_reward;
        agent_summary_all = agent_summary_all + elem.agent_summary;
        upline_total_reward_all = upline_total_reward_all + elem.upline_total_reward;
        upline_summary_all = upline_summary_all + elem.upline_summary;
      });
    let datatofix = data.map((e, i) => ({
      downline_user_id: e.downline_user_id,
      downline_name: e.downline_name,
      total_buy: e.total_buy?.toLocaleString(undefined, { maximumFractionDigits: 2 }),
      downline_receive: e.downline_receive?.toLocaleString(undefined, { maximumFractionDigits: 2 }),
      downline_discount: e.downline_discount?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
      downline_receive_discount: e.downline_receive_discount?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
      downline_total_reward: e.downline_total_reward?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
      agent_total_receive: e.agent_total_receive?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
      agent_total_discount: e.agent_total_discount?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
      agent_total_receive_discount: e.agent_total_receive_discount?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
      agent_total_reward: e.agent_total_reward?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
      upline_receive: e.upline_receive?.toLocaleString(undefined, { maximumFractionDigits: 2 }),
      upline_discount: e.upline_discount?.toLocaleString(undefined, { maximumFractionDigits: 2 }),
      upline_receive_total: e.upline_receive_total?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
      upline_total_reward: e.upline_total_reward?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
      downline_summary: e.downline_summary?.toLocaleString(undefined, { maximumFractionDigits: 2 }),
      agent_summary: e.agent_summary?.toLocaleString(undefined, { maximumFractionDigits: 2 }),
      upline_summary: e.upline_summary?.toLocaleString(undefined, { maximumFractionDigits: 2 }),
    }));
    return [
      ...datatofix,
      {
        downline_name: (
          <SuiBox textAlign="end" width={"100%"}>
            รวม
          </SuiBox>
        ),
        downline_user_id: "",
        downline_name: "รวม",
        total_buy: total_buy.toLocaleString(undefined, { maximumFractionDigits: 2 }),
        downline_receive: downline_receive.toLocaleString(undefined, { maximumFractionDigits: 2 }),
        downline_discount: downline_discount.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }),
        downline_receive_discount: downline_receive_discount.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }),
        agent_total_receive: agent_total_receive.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }),
        agent_total_discount: agent_total_discount.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }),
        agent_total_receive_discount: agent_total_receive_discount.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }),
        upline_receive: upline_receive.toLocaleString(undefined, { maximumFractionDigits: 2 }),
        upline_discount: upline_discount.toLocaleString(undefined, { maximumFractionDigits: 2 }),
        upline_receive_total: upline_receive_total.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }),
        downline_total_reward: downline_total_reward_all.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }),
        downline_summary: downline_summary_all.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }),
        agent_total_reward: agent_total_reward_all.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }),
        agent_summary: agent_summary_all.toLocaleString(undefined, { maximumFractionDigits: 2 }),
        upline_total_reward: upline_total_reward_all.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }),
        upline_summary: upline_summary_all.toLocaleString(undefined, { maximumFractionDigits: 2 }),
      },
    ];
  };
  const isSubmit = () => {
    fetchUser();
  };
  useEffect(() => {
    fetchGetDetailBuy();
  }, []);
  useEffect(() => {
    fetchUser();
  }, [startdate]);

  const setTimeBt = async (value) => {
    setStartdate(value);
  };
  const checkedlotto = (i, index, value) => {
    let data = lottoList;
    data[i].gatcha_list[index].check = value;
    setLottoList([...data]);
  };
  const checkedlottoAll = (value) => {
    let data = lottoList;

    data.map((e, i) => e.gatcha_list.map((el, index) => (el.check = value)));
    setLottoList([...lottoList]);
  };
  const checkedlottoGroupAll = (index, value) => {
    let data = lottoList;
    data[index].gatcha_list.map((e, i) => (e.check = value));
    setLottoList([...data]);
  };

  return (
    <DashboardLayout>
      <SuiBox py={3}>
        <LootoList20>รายงานผลแพ้ชนะ</LootoList20>

        <Grid container spacing={4} pt={5}>
          <Grid xs={12} md={6} item>
            <SuiBox style={{ background: "#FFF", padding: 24, borderRadius: 16 }}>
              <SuiBox>
                <SuiTypography variant="caption">วันที่เริ่ม</SuiTypography>
                <SuiDatePicker
                  value={startdate}
                  onChange={(e) => {
                    setStartdate(moment(e[0]).format("DD/MM/YYYY"));
                  }}
                  input={{
                    placeholder: "Select a date",
                    name: "start_date",
                  }}
                  options={{
                    enableTime: false,
                    time_24hr: false,
                    dateFormat: "d/m/Y",
                    defaultDate: moment().format("DD/MM/YYYY"),
                  }}
                />
              </SuiBox>
              <SuiBox mb={2}>
                <SuiTypography variant="caption">วันที่สิ้นสุด</SuiTypography>
                <SuiDatePicker
                  value={endtdate}
                  onChange={(e) => setEnddate(moment(e[0]).format("DD/MM/YYYY"))}
                  input={{
                    placeholder: "Select a date",
                    name: "start_date",
                  }}
                  options={{
                    enableTime: false,
                    time_24hr: false,
                    dateFormat: "d/m/Y",
                    defaultDate: moment().format("DD/MM/YYYY"),
                  }}
                />
              </SuiBox>
              {/* </Grid> */}
              {/* <Grid md={2} item container height={100} mt={2}> */}
              {/* <Grid container> */}
              <SuiBox display="flex">
                <SuiBox mr={3} mt={2} customClass={classes.customBox}>
                  <SuiButton
                    customClass={classes.customButton}
                    onClick={() => {
                      setStartdate(moment().format("DD/MM/YYYY"));
                      setEnddate(moment().format("DD/MM/YYYY"));
                    }}
                  >
                    วันนี้
                  </SuiButton>
                </SuiBox>

                <SuiBox mr={3} mt={2} customClass={classes.customBox}>
                  <SuiButton
                    customClass={classes.customButton}
                    onClick={() => {
                      setStartdate(moment().subtract(1, "days").format("DD/MM/YYYY"));
                      setEnddate(moment().subtract(1, "days").format("DD/MM/YYYY"));
                    }}
                  >
                    เมื่อวาน
                  </SuiButton>
                </SuiBox>
                <SuiBox mr={3} mt={2} customClass={classes.customBox}>
                  <SuiButton
                    customClass={classes.customButton}
                    onClick={() => {
                      setStartdate(moment().subtract(7, "days").format("DD/MM/YYYY"));
                      setEnddate(moment().format("DD/MM/YYYY"));
                    }}
                    // onClick={() => setTimeBt(moment().subtract(7, "days").format("DD/MM/YYYY"))}
                  >
                    7วันที่แล้ว
                  </SuiButton>
                </SuiBox>
                <SuiBox mr={3} mt={2} customClass={classes.customBox}>
                  <SuiButton
                    customClass={classes.customButton}
                    onClick={() => {
                        setStartdate(moment().subtract(7, "days").startOf('week').add(1, 'day').format("DD/MM/YYYY"));
                        setEnddate(moment().subtract(7, "days").endOf('week').add(1, 'day').format("DD/MM/YYYY"));
                      // setEnddate(moment().format("DD/MM/YYYY"));
                    }}
                    // onClick={() => setTimeBt(moment().subtract(14, "days").format("DD/MM/YYYY"))}
                  >
                    สัปดาท์ที่แล้ว
                  </SuiButton>
                </SuiBox>
                <SuiBox mr={3} mt={2} customClass={classes.customBox}>
                  <SuiButton
                    customClass={classes.customButton}
                    onClick={() => {
                      setStartdate(moment().startOf('month').format("DD/MM/YYYY"));
                      setEnddate(moment().endOf('month').format("DD/MM/YYYY"));
                    }}
                    // onClick={() => setTimeBt(moment().subtract(30, "days").format("DD/MM/YYYY"))}
                  >
                    เดือนนี้
                  </SuiButton>
                </SuiBox>
                <SuiBox mr={3} mt={2} customClass={classes.customBox}>
                  <SuiButton
                    customClass={classes.customButton}
                    onClick={() => {
                      setStartdate(moment().subtract(1, "month").startOf('month').format("DD/MM/YYYY"));
                      setEnddate(moment().subtract(1, "month").endOf('month').format("DD/MM/YYYY"));
                    }}
                    // onClick={() => setTimeBt(moment().subtract(60, "days").format("DD/MM/YYYY"))}
                  >
                    เดือนที่แล้ว
                  </SuiButton>
                </SuiBox>
              </SuiBox>
              <SuiBox mt={2}>
                <SuiButton onClick={() => isSubmit()} customClass={classes.customSubmit}>
                  ยืนยัน
                </SuiButton>
              </SuiBox>
            </SuiBox>
            {/* </Grid> */}
          </Grid>
          <Grid xs={12} md={6} item>
            <SuiBox style={{ background: "#FFF", padding: 24, borderRadius: 16, height: "100%" }}>
              <Grid container>
                {lottoList.map((e, i) => (
                  <Grid xs={6} lg key={i} border="1px solid #000" padding={2}>
                    <LootoList16>{e.group_name}</LootoList16>
                    <SuiButton
                      buttonColor="info"
                      sx={{ marginRight: 1 }}
                      onClick={() => checkedlottoGroupAll(i, true)}
                    >
                      เลือกทั้งหมด
                    </SuiButton>
                    <SuiButton buttonColor="error" onClick={() => checkedlottoGroupAll(i, false)}>
                      เอาออกทั้งหมด
                    </SuiButton>
                    <SuiBox>
                      {e.gatcha_list.map((el, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Switch
                              checked={el.check}
                              onChange={(e) => checkedlotto(i, index, !el.check)}
                            />
                          }
                          label={<SuiTypography variant="caption">{el.gatcha_name}</SuiTypography>}
                        />
                      ))}
                    </SuiBox>
                  </Grid>
                ))}
              </Grid>
              <SuiBox mt={2}>
                <SuiButton
                  buttonColor="info"
                  sx={{ marginRight: 1, fontSize: 18 }}
                  onClick={() => checkedlottoAll(true)}
                >
                  เลือกหวยทั้งหมด
                </SuiButton>
                <SuiButton
                  buttonColor="error"
                  sx={{ marginRight: 1, fontSize: 18 }}
                  onClick={() => checkedlottoAll(false)}
                >
                  เอาหวยออกทั้งหมด
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
      {isLoading ? (
        <>
          <Skeleton variant="rectangular" width={"100%"} height={200} />
          <br />
          <Skeleton variant="rectangular" width={"100%"} height={200} />
        </>
      ) : (
        <>
          <SuiBox py={3}>
            <SuiTypography></SuiTypography>
            {buyListByLottoItem?.rows?.length > 0 && (
              <DataTable table={buyListByLottoItem} mainHeadTable={mainTable} />
            )}
          </SuiBox>
          <SuiBox py={3}>
            {buyListByUserItem?.rows?.length > 0 && (
              <DataTable table={buyListByUserItem} mainHeadTable={mainTableUser} />
            )}
          </SuiBox>
        </>
      )}
    </DashboardLayout>
  );
}
